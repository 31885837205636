import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import environment from '../app/configs/environment';

export const Auth0ProviderWithHistory = ({ children }: { children: React.ReactNode }) => {
  const { AUTH0_DOMAIN: domain, AUTH0_CLIENT_ID: clientId, AUTH0_AUDIENCE: audience } = environment.AUTH0;

  // use navigate instead of history: https://stackoverflow.com/a/70531954
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname);
  // const location = useLocation();
  const onRedirectCallback = () => {
    if (localStorage.getItem('APP_BACK_URL')) {
      navigate(localStorage.getItem('APP_BACK_URL')!);
    } else {
      navigate(-2);
    }
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
      onRedirectCallback={onRedirectCallback}
      // cookieDomain='.ulrichservice.com'
      // cacheLocation={'localstorage'}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}>
      {children}
    </Auth0Provider>
  );
};
