import { Modal as AntModal, ModalProps } from 'antd';
import { ReactComponent as CLoseIcon } from 'shared/icons/close.svg';

function Modal({ ...props }: ModalProps) {
  return (
    <AntModal
      {...props}
      centered
      maskClosable={false}
      styles={{
        mask: {
          backgroundColor: `rgba(189, 189, 189, 0.24)`,
          backdropFilter: `blur(7.5px)`,
          WebkitBackdropFilter: `blur(7.5px)`,
        },
      }}
      style={{
        borderRadius: '20px',
        overflow: 'hidden', // this makes borderRadius work (https://stackoverflow.com/a/59912479)
      }}
      closeIcon={<CLoseIcon className='fill-gray-9 hover:fill-gray-5 transition-colors' />}
    />
  );
}

export default Modal;
