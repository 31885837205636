import { Drawer as AntDrawer, DrawerProps } from 'antd';
import { ReactComponent as CLoseIcon } from 'shared/icons/close.svg';
import styled from 'styled-components';
import { IsDesktopOrLaptop } from 'utils/mediaQuery';

const DrawerWrapper = styled(({ className, ...rest }: DrawerProps) => (
  <AntDrawer rootClassName={className} {...rest}></AntDrawer>
))`
  .ant-drawer-header {
    padding: 15px 20px;
    border: none;
    .ant-drawer-header-title {
      justify-content: flex-end;
      button {
        margin: 0;
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-mask {
    background-color: rgba(189, 189, 189, 0.24);
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(7.5px);
  }
  .ant-drawer-content-wrapper {
    box-shadow: none;
  }
  .ant-drawer-close {
    display: inline-block;
    height: 24px;
    width: 24px;
    padding: 0;
  }
`;
function Drawer({ ...props }: DrawerProps) {
  return (
    <DrawerWrapper
      style={{
        borderRadius: IsDesktopOrLaptop() ? '0px 15px 15px 0px' : '15px 15px 0 0 ',
        boxShadow: '20px 0px 60px rgba(0, 0, 0, 0.25)',
        WebkitBoxShadow: '20px 0px 60px rgba(0, 0, 0, 0.25)',
        // maxHeight: '100vh',
      }}
      closeIcon={<CLoseIcon className='fill-gray-9 hover:fill-gray-5 transition-colors' />}
      {...props}></DrawerWrapper>
  );
}

export default Drawer;
