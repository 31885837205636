import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginLink } from 'shared/components/LoginLink/LoginLink';
import Button from 'shared/components/Button/Button';
import { useSelector } from 'react-redux';
import {
  select3dOrderConfigs,
  selectOrderLine,
  selectOrderPurchaseInfo,
  selectIs3dOrderLine,
} from 'features/order/Order.slice';
import moneyFormat from 'helpers/moneyFormat';
import { toShortString } from 'helpers/dateFormats';
import PaymentModal from 'features/payment/Payment.modal';
import { IsDesktopOrLaptop, IsTabletOrMobile } from 'utils/mediaQuery';
import { ReactComponent as EyeIcon } from 'shared/icons/eye.svg';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Drawer from 'shared/antd/components/Drawer';
import AllConfigurationInfo from '../OrderDetail/AllConfigurationInfo/AllConfigurationInfo.component';
import PurchaseSummary from '../OrderDetail/PurchaseSummary/PurchaseSummary.component';
import { getOrderedFrom } from 'utils/getOrderedFrom';
import { formatPhoneNumber } from 'helpers/phoneNumberFormat';
import hasMasterId from 'utils/hasMasterId';
import { isBoolean, isNaN } from 'lodash';
import isPayable from 'utils/isPayable';
import isRtoOrder from 'utils/isRtoOrder';

export const OrderOverview = () => {
  const { isAuthenticated } = useAuth0();
  const orderLineState = useSelector(selectOrderLine);
  const is3dOrderLine = useSelector(selectIs3dOrderLine);
  const order3dConfigsState = useSelector(select3dOrderConfigs);
  const orderPurchaseInfoState = useSelector(selectOrderPurchaseInfo);
  const projectManager = orderLineState.members.filter((e) => e.role === 'ProjectManager' || e.role === null)[0];
  const OrderedFrom = getOrderedFrom(orderLineState.siteId);
  const ShippingTo = orderLineState.addresses.filter((e) => e.type === 'Shipping')[0];
  const [open, setOpen] = useState(false);
  const [paymentModelKey, setPaymentModelKey] = useState(new Date().getTime());

  const [showPurchaseSummary, setShowPurchaseSummary] = useState(false);
  const [showConfigurationInfo, setShowConfigurationInfo] = useState(false);
  const { resourceToken = '' } = useParams();

  // only need to extract 'Total Order' from order configs
  const [totalOrder, setTotalOrder] = useState<number>();
  useEffect(() => {
    if (resourceToken) {
      setPaymentModelKey(new Date().getTime());
    }
  }, [resourceToken]);

  useEffect(() => {
    if (!isBoolean(is3dOrderLine)) return;
    setTotalOrder(() => {
      return Number(
        is3dOrderLine
          ? order3dConfigsState.find((item) => item['Id'] === 'TotalOrder')?.Extended?.extPrice
          : orderPurchaseInfoState?.totalOrder,
      );
    });
  }, [orderLineState, is3dOrderLine, order3dConfigsState, orderPurchaseInfoState]);

  return (
    <>
      {/* For Mobile, Tablet and Desktop (Un-authorized)*/}
      {(!isAuthenticated || IsDesktopOrLaptop()) && (
        <div className='mt-5 xl:mt-[60px] flex justify-center w-full'>
          <div className='bg-gray-8 rounded-[15px] flex flex-col w-full max-w-2xl xl:max-w-fit'>
            <div className='flex flex-col xl:flex-row'>
              <div className='flex flex-col my-[15px] ml-[10px] xl:my-[30px] xl:ml-5 bg-transparent xl:min-w-[350px]'>
                <div className='flex justify-between xl:space-x-[60px]'>
                  {/* Manager */}
                  <div className='flex flex-col xl:min-w-[200px]'>
                    {projectManager ? (
                      <div>
                        <h4 className='text-gray-7 text-xs xl:text-lg font-bold xl:font-medium !leading-[130%]'>
                          Your Project Manager
                        </h4>
                        <div className='mt-[10px] flex flex-col h-12 xl:min-h-[100px] justify-between min-w-full font-normal text-xs xl:text-base text-base-3 !leading-[130%]'>
                          <h6>{projectManager?.name ?? ''}</h6>
                          <h6 className='leading-4'>{projectManager?.email ?? ''}</h6>
                          <h6 className='max-w-[280px]'>
                            For questions call our customer service team at: {formatPhoneNumber('8174096638')}
                          </h6>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h4 className='text-gray-7 text-xs xl:text-lg font-bold xl:font-medium !leading-[130%]'>
                          Customer Service
                        </h4>
                        <div className='mt-[10px] flex flex-col h-12 xl:h-[75px] justify-between min-w-full font-normal text-xs xl:text-base text-base-3 !leading-[130%]'>
                          <h6>{formatPhoneNumber('8174096638')}</h6>
                        </div>
                      </div>
                    )}
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.ulrichlifestyle.com/customer-service/'
                      className='w-fit mt-[10px] font-bold text-gray-7 text-[10px] xl:text-xs !leading-[130%] underline hover:text-gray-5'>
                      Submit a service or warranty request
                    </a>
                  </div>
                  {/* Divider */}
                  <div className=' border-l border-solid border-base-1 !ml-[10px]'></div>
                  {/* Delivery / Install */}
                  <div className={`flex flex-col xl:min-w-[200px] !mr-5 !!leading-[130%]`}>
                    <h4 className='text-gray-7 text-xs xl:text-lg font-bold xl:font-medium !leading-[130%]'>
                      Delivery / Install
                    </h4>
                    <div className='mt-[10px] flex flex-col h-[30px] xl:h-12 justify-between min-w-full font-normal text-xs xl:text-base text-base-3 !leading-[130%]'>
                      <h6>{`Target Date: ${
                        orderLineState?.deliveryInfo?.targetDate
                          ? toShortString(orderLineState.deliveryInfo?.targetDate)
                          : ''
                      }`}</h6>
                      <h6>{`Technician: ${orderLineState?.deliveryInfo?.technician ?? ''}`}</h6>
                    </div>
                  </div>
                </div>
              </div>

              {/* Current Amount */}
              {!isAuthenticated && (
                <div className='mx-[10px] mb-[15px] xl:mb-0 xl:ml-[60px] mt-[5px] xl:my-5 xl:mr-[30px] flex justify-between xl:justify-start xl:block'>
                  <div>
                    {hasMasterId(orderLineState) && (
                      <div className='ml-[6.7px]'>
                        <div className='text-gray-7 '>
                          <div className='font-medium text-xs xl:text-sm !leading-[130%]'>Current Amount Due</div>
                          <div className='font-bold text-[26px] xl:text-3xl !leading-[130%] mt-1'>
                            {moneyFormat(orderLineState.currentAmountDue)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='text-center'>
                    {hasMasterId(orderLineState) && isPayable(orderLineState) && (
                      <>
                        <Button
                          disabled={orderLineState.currentAmountDue === 0 && isRtoOrder(orderLineState)}
                          buttonType='primary'
                          className='text-[10px] xl:text-base leading-6 py-[6px] xl:py-[13px] px-[30px] rounded-[30px] xl:mt-[15.8px] tracking-[1px]'
                          onClick={() => {
                            setPaymentModelKey(new Date().getTime());
                            setOpen(true);
                          }}>
                          MAKE A PAYMENT
                        </Button>

                        <PaymentModal
                          open={open}
                          setOpen={setOpen}
                          key={paymentModelKey}
                          amountDue={orderLineState.currentAmountDue ?? 0}></PaymentModal>
                      </>
                    )}

                    <div className='mt-[10px]'>
                      <span className='!leading-[130%] text-base-3 font-medium'>
                        <LoginLink />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isAuthenticated && (
            <div className='ml-10 bg-base-1 rounded-[15px] flex'>
              <div className='p-[30px] pb-[29px] bg-transparent flex space-x-[60px] justify-between '>
                {/* Ordered Form */}
                <div className='flex flex-col max-w-[180px]'>
                  <h4 className='text-gray-7 text-xs xl:text-lg font-bold xl:font-medium !leading-[130%]'>
                    Ordered From
                  </h4>
                  <div className='mt-[10px] space-y-[6px] flex flex-col  justify-between min-w-[180px] font-normal text-xs xl:text-base !leading-[130%] text-base-3'>
                    <h5 className='font-semibold'>{OrderedFrom?.company ?? ''}</h5>
                    <h6>{OrderedFrom?.add_1 ?? ''}</h6>
                    <h6>
                      {`${OrderedFrom?.city ? `${OrderedFrom?.city},` : ''}`} {OrderedFrom?.state ?? ''}&nbsp;
                      {OrderedFrom?.zip ?? ''}
                    </h6>
                    <h6 className='leading-[calc(130%+6px)] !mt-[3px]'>
                      {formatPhoneNumber(OrderedFrom?.phone ?? '')}
                    </h6>
                  </div>
                </div>
                {/* Divider */}
                <div className='border-l border-solid border-gray-6 '></div>
                {/* Ship To */}
                <div className='flex flex-col max-w-[200px]'>
                  <h4 className='text-gray-7 text-xs xl:text-lg font-bold xl:font-medium !leading-[130%]'>Ship To</h4>
                  <div className='mt-[10px] space-y-[6px] flex flex-col  justify-between min-w-[180px] font-normal text-xs xl:text-base !leading-[130%] text-base-3'>
                    <h5 className='font-semibold'>{ShippingTo?.title ?? ''}</h5>
                    <h6>{ShippingTo?.address ?? ''}</h6>
                    <h6>
                      {`${ShippingTo?.city ? `${ShippingTo?.city},` : ''}`} {ShippingTo?.state ?? ''}&nbsp;
                      {ShippingTo?.zip ?? ''}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* For Mobile and Tablet (Authorized) */}
      {IsTabletOrMobile() && isAuthenticated && (
        <div className='flex flex-col mt-5 w-full space-y-[10px] max-w-2xl items-center'>
          <div className='flex flex-col bg-gray-8 py-[15px] px-[10px] rounded-[15px] w-full min-h-[170px] justify-between'>
            {/* Project Manager */}
            <div className='flex justify-between items-center'>
              {projectManager ? (
                <div className='flex flex-col max-w-[160px] '>
                  <h4 className='text-gray-7 text-xs font-bold !leading-[130%]'>Your Project Manager</h4>
                  <div className='mt-[10px] flex flex-col min-h-[42px] justify-between min-w-full font-normal text-[10px] text-base-3 !leading-[130%]'>
                    <h6>{projectManager?.name ?? ''}</h6>
                    <h6 className='leading-6'>{projectManager?.email ?? ''}</h6>
                    <h6>For questions call our customer service team at: {formatPhoneNumber('8174096638')}</h6>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col'>
                  <h4 className='text-gray-7 text-xs font-bold !leading-[130%]'>Customer Service</h4>
                  <div className='mt-[10px] flex flex-col min-h-[42px] justify-between min-w-full font-normal text-[10px] text-base-3 !leading-[130%]'>
                    <h6>{formatPhoneNumber('8174096638')}</h6>
                  </div>
                </div>
              )}

              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.ulrichlifestyle.com/customer-service/'
                className='max-w-[90px] font-bold text-gray-7 text-[10px] !leading-[130%] underline'>
                Submit a service or warranty request
              </a>
            </div>
            {/* Divider */}
            <div className='w-full h-[1px] bg-base-1 mt-[5px] mb-[5px]'></div>
            {/* Delivery / Install */}
            <div className='flex justify-between items-center'>
              <div className='flex flex-col'>
                <h4 className='text-gray-7 text-xs font-bold !leading-[130%]'>Delivery / Install</h4>
                <div className='mt-[10px] flex flex-col min-h-[26px] justify-between min-w-full font-normal text-[10px] text-base-3 !leading-[130%]'>
                  <h6>{`Target Date: ${
                    orderLineState.deliveryInfo && orderLineState.deliveryInfo.targetDate
                      ? toShortString(orderLineState.deliveryInfo?.targetDate)
                      : ''
                  }`}</h6>
                  <h6>{`Technician: ${orderLineState?.deliveryInfo?.technician ?? ''}`}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col bg-base-1 py-[15px] px-[10px] rounded-[15px] w-full min-h-[131px] justify-between'>
            {/* Ordered From */}
            <div className='flex justify-between items-center'>
              <div className='flex flex-col'>
                <h4 className='text-gray-7 text-xs font-bold !leading-[130%]'>Ordered From</h4>
                <div className='mt-[5px] min-w-full font-normal text-[10px] text-base-3 !leading-[130%]'>
                  <h5 className='font-semibold leading-[calc(130%+2px)]'>{OrderedFrom?.company ?? ''}</h5>
                  <h6 className='leading-[calc(130%+2px)]'>{OrderedFrom?.add_1 ?? ''}</h6>
                  <h6 className='leading-[calc(130%+2px)]'>
                    {`${OrderedFrom?.city ? `${OrderedFrom?.city},` : ''}`} {OrderedFrom?.state ?? ''}&nbsp;
                    {OrderedFrom?.zip ?? ''}
                  </h6>
                  <h6 className='leading-[calc(130%+2px)]'>{formatPhoneNumber(OrderedFrom?.phone ?? '')}</h6>
                </div>
              </div>
            </div>
            {/* Divider */}
            <hr className='w-full h-[1px] bg-gray-6'></hr>
            {/* <hr className='w-full h-[1px] bg-gray-'></hr> */}
            {/* Ship To */}
            <div className='flex justify-between items-center'>
              <div className='flex flex-col'>
                <h4 className='text-gray-7 text-xs font-bold !leading-[130%]'>Ship To</h4>
                <div className='mt-[5px] min-w-full font-normal text-[10px] text-base-3 !leading-[130%]'>
                  <h5 className='font-semibold leading-[calc(130%+2px)]'>{ShippingTo?.title ?? ''}</h5>
                  <h6 className='leading-[calc(130%+2px)]'>{ShippingTo?.address ?? ''}</h6>
                  <h6 className='leading-[calc(130%+2px)]'>
                    {`${ShippingTo?.city ? `${ShippingTo?.city},` : ''}`} {ShippingTo?.state ?? ''}&nbsp;
                    {ShippingTo?.zip ?? ''}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {hasMasterId(orderLineState) && (
            <>
              <div className='flex justify-between w-full'>
                {/* Purchase Mobile Summary */}
                {/* If orderConfigs or purchaseInfo is unavailable (both will make number casted totalOrder `NaN`),
                then show 'No data found' message & disable clicking on 'See Purchase Summary' */}
                <div className='py-[15px] px-[18px] min-h-[105px] bg-gray-8 rounded-[15px] flex flex-col justify-between max-w-[164px]'>
                  <h5 className='text-gray-7 text-xs font-medium'>Total Order</h5>
                  {isNaN(totalOrder) ? (
                    <h6 className='text-base-3 xl:text-[18px] text-[14px]'>No data found.</h6>
                  ) : (
                    <h2 className='text-base-3 font-medium !leading-[130%]'>{moneyFormat(totalOrder)}</h2>
                  )}
                  <div
                    className={`flex items-center space-x-1 cursor-pointer ${
                      isNaN(totalOrder) && 'pointer-events-none opacity-40'
                    }`}
                    onClick={() => setShowPurchaseSummary(true)}>
                    <EyeIcon width={14.4} className='flex-[0_0_14px] fill-gray-7'></EyeIcon>
                    <p className='flex-1 text-[10px] text-gray-7 underline !leading-[130%]'>See Purchase Summary</p>
                  </div>
                  <Drawer
                    height={'auto'}
                    width={'100%'}
                    open={showPurchaseSummary}
                    onClose={() => setShowPurchaseSummary(false)}
                    placement='bottom'
                    styles={{ header: { display: 'none' } }}>
                    <PurchaseSummary onClose={() => setShowPurchaseSummary(false)} />
                  </Drawer>
                </div>
                {/* PaymentSched/PaymentMade/Document */}
                <div className='py-[15px] px-[18px] min-h-[105px] bg-gray-8 rounded-[15px] flex flex-col justify-between max-w-[164px]'>
                  <div className=''>
                    <h5 className='text-gray-7 text-xs font-medium'>Current Amount Due</h5>
                    <h2 className='text-base-3 font-medium !leading-[130%]'>
                      {moneyFormat(orderLineState.currentAmountDue)}
                    </h2>
                  </div>
                  <div className='flex items-center space-x-1'>
                    <EyeIcon width={14.4} className='flex-[0_0_14px] fill-gray-7'></EyeIcon>
                    <Link
                      to={`/${resourceToken}/payment-schedule`}
                      className='flex-1 text-[10px] text-gray-7 underline !leading-[130%]'>
                      See Payments, Schedule & Documents
                    </Link>
                  </div>
                </div>
              </div>
              {is3dOrderLine && (
                <div>
                  <Button
                    className='!text-[10px] mt-5 px-[30px] py-1.5 xl:py-[13px] rounded-[30px] tracking-normal'
                    buttonType='light'
                    disabled={order3dConfigsState.length === 0}
                    onClick={() => setShowConfigurationInfo(true)}>
                    VIEW CONFIGURATION INFO
                  </Button>
                  <Drawer
                    open={showConfigurationInfo}
                    styles={{ header: { display: 'none' } }}
                    height='calc(100% - 33px)'
                    width={'100%'}
                    placement='bottom'
                    onClose={() => setShowConfigurationInfo(false)}>
                    <AllConfigurationInfo onClose={() => setShowConfigurationInfo(false)} />
                  </Drawer>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
