import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Button, { CustomButtonProps } from '../Button/Button';
import { ReactComponent as LogoutIcon } from '../../../shared/icons/logout.svg';
export interface LogoutButtonProp extends CustomButtonProps {
  show: boolean;
}
export const LogoutButton = ({ show: isAuthenticated, className, ...props }: LogoutButtonProp): JSX.Element => {
  const { logout } = useAuth0();
  const location = useLocation();
  const logoutHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    localStorage.setItem('APP_BACK_URL', location.pathname);
    logout({ logoutParams: { returnTo: window.location.origin + '/logoutCallback' } });
  };

  return (
    <>
      {isAuthenticated && (
        <Button {...props} className={`${className} group`} onClick={logoutHandler}>
          <LogoutIcon className='w-4 xl:w-auto h-4 stroke-base-3 group-hover:stroke-gray-5 transition-all mr-1 xl:mr-[10px]' />
          LOGOUT
        </Button>
      )}
    </>
  );
};
