import { Input, InputProps, InputRef } from 'antd';
import { ChangeEvent, RefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import IMask, { MaskedNumberOptions, MaskedPatternOptions, MaskedPattern, MaskedNumber } from 'imask';
import React from 'react';
import { InputFocusOptions } from 'antd/es/input/Input';
import { Rule } from 'antd/es/form';

export const MASKED_INPUT_MASK_COMPLETE_EVENT = 'MASKED_INPUT_MASK_COMPLETE_EVENT';
export type MaskedInputProps = InputProps &
  React.RefAttributes<InputRef> & {
    maskOptions: MaskedPatternOptions | MaskedNumberOptions;
  };

export type MaskedInputRef = InputRef & {
  getMaskedValue: () => string | undefined;
  getUnMaskedValue: () => string | undefined;
  validate: () => boolean;
  isComplete: () => boolean;
};

export const getMaskedInputValidators = (ref: RefObject<MaskedInputRef>, message?: string): Rule[] => [
  {
    validator: (_, val) => {
      const valid = (val as string)?.length > 0 ? ref.current?.isComplete() : true;
      return valid ? Promise.resolve() : Promise.reject();
    },
    validateTrigger: 'onBlur',
    message: message,
  },
];

export const MaskedInput = React.forwardRef<Partial<MaskedInputRef>, MaskedInputProps>(function MaskedInput(
  // eslint-disable-next-line react/prop-types
  { value, maskOptions, onChange, placeholder, ...props }: MaskedInputProps,
  antdRef,
): JSX.Element {
  const masked = useRef<MaskedPattern | MaskedNumber>();
  const [inputValue, setInputValue] = useState<string>(value?.toString() ?? '');
  const [maskedPlaceholder, setMaskedPlaceholder] = useState<string>();
  const innerRef = React.useRef<InputRef>(null);

  useImperativeHandle(antdRef, () => {
    return {
      input: innerRef.current?.input ?? null,
      blur: () => innerRef.current?.blur(),
      focus: (options?: InputFocusOptions | undefined) => innerRef.current?.focus(options),
      select: () => innerRef.current?.select(),
      setSelectionRange: (start: number, end: number, direction?: 'forward' | 'backward' | 'none' | undefined) =>
        innerRef.current?.setSelectionRange(start, end, direction),
      getMaskedValue: () => masked.current?.value,
      getUnMaskedValue: () => masked.current?.unmaskedValue,
      validate: () => {
        const result = masked.current?.doValidate({}) ?? false;

        return result;
      },
      isComplete: () => {
        const result = masked.current?.isComplete ?? false;

        return result;
      },
    };
  }, []);

  const updateValue = useCallback(
    (value: string) => {
      if (masked.current && maskOptions) {
        masked.current.resolve(value);
        const maskedValue = masked.current.value;
        if (inputValue !== maskedValue) {
          setInputValue(maskedValue);
        }

        return maskedValue;
      }
    },
    [inputValue, maskOptions],
  );

  useEffect(() => {
    if (!masked.current) {
      const m = IMask.createMask({
        ...maskOptions,
      });
      masked.current = m;
      setMaskedPlaceholder(
        IMask.createPipe({ ...maskOptions, lazy: false } as MaskedPatternOptions | MaskedNumberOptions)(''),
      );
      updateValue(inputValue);
    }
  }, [inputValue, maskOptions, updateValue]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const maskedValue = updateValue(e.target.value);
      if (maskedValue) {
        e.target.value = maskedValue;
      }

      onChange?.(e);
      if (masked.current?.isComplete) {
        window.setTimeout(() => e.target.dispatchEvent(new Event(MASKED_INPUT_MASK_COMPLETE_EVENT)), 0);
      }
    },
    [onChange, updateValue],
  );

  useEffect(() => {
    if (value !== undefined) {
      updateValue(value.toString());
    }
  }, [inputValue, updateValue, value]);

  return (
    <Input
      {...props}
      onChange={handleChange}
      value={inputValue}
      placeholder={placeholder ?? maskedPlaceholder}
      ref={innerRef}
    />
  );
});
