import { BaseOrderLine } from 'shared/models/base-order-line.model';

/**
 * This function checks if an order has Master Id or not. Use this to render/hide specific section(s) of the app.
 * Current condition:
 * - `orderLine.configurationId` must be available.
 */
const isPayable = (order: BaseOrderLine): boolean => {
  return 'Regular' === order.type && 'Cabin' !== order.productItemType && 'Deck' !== order.productItemType;
};

export default isPayable;
