import { InputProps } from 'antd';
import { Rule } from 'antd/es/form';
import React, { RefObject } from 'react';
import { ErrorMessage } from 'shared/components/Form/Form';
import { FormStateValue } from 'shared/models/form.state.model';
import { MaskedInput, MaskedInputRef } from './MaskedInput';

export const MONEY_MASK = '$ money';
export const isMoney = (value: FormStateValue): boolean => {
  const reg = new RegExp(`^${MONEY_MASK.replace('money', '-?\\d+([.,]\\d+)?').replace('$', '\\$')}$`);
  return reg.test(value.toString());
};

export const moneyToNumber = (value: FormStateValue): number => {
  // return Number(value.toString().replace(MONEY_MASK.replace('money', ''), ''));
  return Number(value.toString().replace(/[^0-9\\.-]+/g, ''));
};

export type MoneyInputRef = MaskedInputRef & {
  validators: Rule[];
};
export type MoneyInputInputProps = InputProps & React.RefAttributes<MoneyInputRef>;
export const getMoneyValidators = (ref: RefObject<MoneyInputRef>, message?: string): Rule[] => [
  {
    validator: () => {
      const valid = (ref.current?.getUnMaskedValue()?.trim().length ?? 0) > 0;

      return valid ? Promise.resolve() : Promise.reject();
    },
    message: message ?? <ErrorMessage>{`${ref.current?.input?.name ?? 'Field'} is required`}</ErrorMessage>,
  },
];

export const MoneyInput = React.forwardRef<Partial<MoneyInputRef>, MoneyInputInputProps>(function MoneyInput(
  // eslint-disable-next-line react/prop-types
  { onBlur, ...props }: MoneyInputInputProps,
  innerRef,
): JSX.Element {
  return (
    <MaskedInput
      className='h-[42px] border-solid border-1-[#DEDEDE] rounded-[15px] leading-6 text-sm text-base-3 px-[16px] py-[9px]'
      maskOptions={{
        mask: '$ money',
        lazy: false,
        blocks: {
          money: {
            mask: Number,
            thousandsSeparator: ',',
            radix: '.',
            mapToRadix: ['.'],
            scale: 2,
            normalizeZeros: false,
            padFractionalZeros: false,
            min: 0,
          },
        },
      }}
      onBlur={(e) => {
        const value = e.target.value;
        if (value.endsWith('.') || value.endsWith(',')) {
          e.target.value += '00';
        }
        onBlur?.(e);
      }}
      {...props}
      ref={innerRef}
    />
  );
});
